<template>
  <div style="background: #F6F6F7;">
    <pcHeaders></pcHeaders>
    <div style="display: flex;justify-content: center;background: #fff;width: 100%;height: 100px;align-items: center;">
      <img style="width: 1000px;height: 80px;"
           src="../../assets/images/lc02.png"
           alt="">
    </div>
    <div class="flex"
         style="justify-content: center;">
      <div class="banners">
        <div class="flex"
             style="font-size: 16px;justify-content: space-between;">
          <div style="color: #F16C62;">
            *相同的课程不允许重复报名
          </div>

          <div>
            已选购课程数量:<span style="color: #F16C62;">{{ chooseNum }}</span>
            课程学时:<span style="color: #F16C62;">{{hour}}</span>
            考试数量:<span style="color: #F16C62;">{{ examNum }}</span>
            考试学时:<span style="color: #F16C62;">{{ examHour}}</span>
            报名费用:<span style="color: #F16C62;">{{ cost.toFixed(1) }}元</span>
          </div>
        </div>

        <div class="flex"
             style="font-size: 16px;justify-content: space-between;margin-top: 30px;"
             v-if="independentExamList.length !== 0">
          <div class="flex"
               style="">
            <div>
              <img style="margin-top: 2px;"
                   src="../../assets/images/02_03.png"
                   alt="">
            </div>
            <div style="margin-left: 5px;font-size: 18px;">
              独立考试
            </div>
          </div>

          <div class="goBtn"
               @click="addCar">
            加入购物车
          </div>
        </div>

        <div style="margin-top: 25px;"
             v-if="independentExamList.length !== 0">
          <el-table ref="multipleTable"
                    :data="independentExamList"
                    tooltip-effect="dark"
                    style="width: 100%"
                    max-height="600">
            <el-table-column label="考试封面"
                             align="center">
              <template slot-scope="scope">
                <img style="width: 100px;height: 80px;"
                     v-if="scope.row.quizImage !== '' && scope.row.quizImage !== null"
                     :src="scope.row.quizImage"
                     alt="">
                <img style="width: 100px;height: 80px;"
                     v-else
                     src="../../assets/images/noimg.png"
                     alt="">
              </template>
            </el-table-column>
            <el-table-column prop="name"
                             label="考试名称"
                             align="center">
              <template slot-scope="scope">
                <span style="color: #4077C0;">{{ scope.row.name }}</span>
              </template>
            </el-table-column>

            <el-table-column prop="C_CREDITHOUR"
                             label="总分/及格分数"
                             align="center">
              <template slot-scope="scope">
                {{ scope.row.totalPoint }} / {{ scope.row.passscore }}
              </template>
            </el-table-column>

            <el-table-column prop="credit"
                             label="学时"
                             align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.credit.toFixed(1) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="timelimit"
                             label="考试时长"
                             align="center">

            </el-table-column>
            <el-table-column prop="price"
                             label="价格"
                             align="center">
              <template slot-scope="scope">
                <span style="color: #FF7D5F;">￥{{ scope.row.price.toFixed(1) }}</span>
              </template>
            </el-table-column>
          </el-table>

          <!-- <div style="display: flex;justify-content: center;margin-top: 15px;">
            <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-size="pagesize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
          </div> -->

        </div>

        <div class="flex"
             style="font-size: 16px;justify-content: space-between;margin-top: 30px;">
          <div class="flex"
               style="">
            <div>
              <img style="margin-top: 2px;"
                   src="../../assets/images/02_03.png"
                   alt="">
            </div>
            <div style="margin-left: 5px;font-size: 18px;">
              课程列表（*已购买课程不可选）
            </div>

          </div>
          <div class="goBtn"
               v-if="independentExamList.length == 0"
               @click="addCar">
            加入购物车
          </div>
        </div>

        <div style="margin-top: 25px;">
          <el-table ref="multipleTable"
                    :data="classCourseList"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    height="600">
            <el-table-column type="selection"
                             width="55"
                             :selectable="selectHandle">
            </el-table-column>
            <el-table-column label="课程封面"
                             align="center">
              <template slot-scope="scope">
                <img style="width: 100px;height: 80px;"
                     v-if="scope.row.C_IMAGE !== '' && scope.row.C_IMAGE !== null"
                     :src="scope.row.C_IMAGE"
                     alt="">
                <img style="width: 100px;height: 80px;"
                     v-else
                     src="../../assets/images/noimg.png"
                     alt="">
              </template>
            </el-table-column>
            <el-table-column prop="C_NAME"
                             label="课程名称"
                             align="center">
              <template slot-scope="scope">
                <span style="color: #4077C0;">{{ scope.row.C_NAME }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="C_CREDITHOUR"
                             label="学时"
                             align="center">
              <template slot-scope="scope">
                <span>{{scope.row.C_CREDITHOUR.toFixed(1) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="C_PRICE"
                             label="价格"
                             align="center">
              <template slot-scope="scope">
                <span style="color: #FF7D5F;">￥{{tClass.CREDIT_PRICE *  scope.row.C_CREDITHOUR.toFixed(1) }}</span>
              </template>
            </el-table-column>
          </el-table>

          <!-- <div style="display: flex;justify-content: center;margin-top: 15px;">
            <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-size="pagesize"
                         layout="total, sizes, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
          </div> -->

        </div>
      </div>
    </div>
    <pcBottom></pcBottom>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import { toSelectLeaningCourse, doShoppingCart } from '@/api/car.js';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      query: {},
      currentPage: 1, //初始页
      pagesize: 10, //每页的数据
      total: 0, //总条数
      newArry: [], //循环数据
      classCourseList: [], //课程列表
      independentExamList: [], //独立考试列表
      seletList: [], //选中的数据
      tClass: {},
      totle: {},
      carFalg: true //禁止重复点击
    };
  },
  // 监听属性 类似于data概念
  computed: {
    hour() {
      let total = 0;
      this.seletList.forEach(v => {
        total += v.C_CREDITHOUR;
      });
      return total;
    },
    examNum() {
      let total = 0;
      total = this.totle.num;
      return total;
    },
    examHour() {
      let total = this.totle.totalCredit;
      this.independentExamList.forEach(v => {
        total += v.credit;
      });
      return total;
    },
    cost() {
      let total = this.totle.totalPrice;
      let num = 0;
      this.seletList.forEach(v => {
        num += v.C_CREDITHOUR;
      });
      total = total + num * this.tClass.CREDIT_PRICE;
      return total;
    },
    chooseNum() {
      let total = 0;
      total = this.seletList.length;
      return total;
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //selectHandle
    selectHandle(row, index) {
      console.log(row, index);
      return row.isShopping == '01';
    },
    handleSizeChange: function (size) {
      this.pagesize = size;
      this.getclassCourseList();
    },
    handleCurrentChange: function (page) {
      this.currentPage = page;
      this.getclassCourseList();
    },
    //前端自己分页
    getclassCourseList: function () {
      // this指向改一下
      var that = this;
      var list = that.classCourseList; //后端回来表格的数据
      //渲染的数据newArry赋值
      this.newArry = list.filter(
        (item, index) => index < that.currentPage * that.pagesize && index >= that.pagesize * (that.currentPage - 1)
      ); //根据页数显示相应的内容
      this.total = list.length;
    },
    //加入购物车
    addCar() {
      // if (this.hour <= this.tClass.CLASS_CREDITHOUR) {
      //   this.$message('勾选课程达不到要求学时，无法加入购物车！')
      //   return false
      // }
      let arr = [];
      if (this.seletList.length == 0) {
        this.$message('请选择需要购买的课程！');
        return false;
      }
      let data = {
        classId: this.query.classId
      };
      this.seletList.forEach(v => {
        arr.push(v.ID);
      });
      data.ids = arr.toString();
      if (this.carFalg) {
        this.carFalg = false;
        doShoppingCart(data).then(res => {
          console.log(res.data);
          if (res.data.data.code == '1') {
            this.$message({
              message: '添加购物车成功！',
              type: 'success'
            });
            this.$router.push('/car');
            this.carFalg = true;
          } else {
            this.$message({
              message: res.data.data.msg,
              type: 'warning'
            });
            this.carFalg = true;
          }
        });
      }
    },
    //获取选择的课程
    getList() {
      this.query.classId = this.$route.query.id;
      toSelectLeaningCourse(this.query).then(res => {
        console.log(res.data);
        this.classCourseList = res.data.data.classCourseList;
        this.independentExamList = res.data.data.independentExamList;
        this.tClass = res.data.data.tClass;
        this.totle = res.data.data;
        // this.getclassCourseList();
      });
    },
    purchase() {},
    //选择
    handleSelectionChange(e) {
      console.log(e);
      this.seletList = e;
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
/deep/ .el-checkbox__input.is-disabled .el-checkbox__inner {
  background-color: #dcdfe6 !important;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border: 1px solid #409eff !important;
}
/deep/ .el-checkbox__inner {
  border: 1px solid #666 !important;
}
.flex {
  display: flex;
}
.banners {
  background: #f6f6f7;
  padding: 20px 0;
  width: 1200px;
  .goBtn {
    width: 160px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    background: #ff9f53;
    font-size: 15px;
    cursor: pointer;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }
}
</style>